/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Global cross-site imports

// import "@popperjs/core/dist/umd/popper.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


// trigger an immediate page refresh when an update is found
// export const onServiceWorkerUpdateReady = () => window.location.reload();
